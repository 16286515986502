import Button from "@atoms/Button";
import React, { useState } from "react";
import SelectTag from "@components/Sunshine/DropDown";
import { useForm, Controller } from "react-hook-form";
import { yupValidator } from "../lib/yup-validator";
import * as yup from "yup";
import InputGroup from "@molecules/InputGroup";
import { createSendMessage, sendOtpToSunshineUser, verifyOtpToSunshineUser } from "@services/sunshine.service";
import Image from "next/image";
import HeadingNew from "@atoms/HeadingNew";
import TextNew from "@atoms/TextNew";
import { googleAdsLabel, triggerGoogleAddConversion } from "utils/constants";
function BookingFormSunshine({ onClose }) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        getValues,
    } = useForm({
        mode: "onChange",
        resolver: yupValidator(
            yup.object().shape({
                name: yup
                    .string()
                    .required("Name is mandatory")
                    .matches(/^[a-zA-Z ]*$/, "Please Enter Valid Name"),
                contact: yup
                    .string()
                    .required("Contact Number is mandatory")
                    .matches(/^[6789]\d{9}$/, "Invalid Phone Number"),
                location: yup
                    .string().required("Location is mandatory"),
                serviceReq: yup
                    .string().required("Service request is mandatory"),
            })
        ),
    });

    const {
        register: registerOtp,
        handleSubmit: handleSubmitOtp,
        reset: resetOtp,
        formState: { errors: errorsOtp }
    } = useForm({
        mode: "onChange",
        resolver: yupValidator(
            yup.object().shape({
                otp: yup
                    .string()
                    .required("OTP is mandatory")
                    .matches(/^\d{4}$/, "Invalid OTP")
            })
        ),
    });

    const [display, setDisplay] = useState('form')
    const [userInfo, setUserInfo] = useState(null)
    const [showError, setShowError] = useState(null)
    const [showSuccess, setShowSuccess] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const sendOtp = (data, isResend) => {
        sendOtpToSunshineUser({ ...data, isResend }).then(response => {
            if (response.status) {
                setUserInfo(data)
                setDisplay('otp')
                setShowError(null)
                setShowSuccess(isResend ? 'OTP Re-Sent Successfully' : null)
                setIsLoading(false)
                if (!isResend) {
                    triggerGoogleAddConversion(googleAdsLabel.SUNSINE_FORM_SUBMITTED_LABEL)
                }
            } else {
                setShowError(response.message)
                setShowSuccess(null)
                setIsLoading(false)
            }
        }).catch((error) => {
            setShowError("Something went wrong !")
            setShowSuccess(null)
            setIsLoading(false)
        })
    }

    const onSubmit = (data) => {
        setIsLoading(true)
        sendOtp(data, false)
    };

    const onSubmitOtp = (data) => {
        setIsLoading(true)
        verifyOtpToSunshineUser(userInfo.contact, data.otp).then(response => {
            if (response.status && response.entity) {
                createSendMessage(userInfo).then((res) => {
                    if (res.status) {
                        setIsLoading(false)
                        setDisplay('message')
                        reset({
                            name: '',
                            contact: '',
                            location: '',
                            serviceReq: '',
                            childAge: ''
                        })
                        resetOtp({ otp: '' })
                        setUserInfo(null)
                        setShowError(null)
                        setShowSuccess(null)
                        setDisplay('form')
                        triggerGoogleAddConversion(googleAdsLabel.SUNSINE_OTP_VERIFIED_LABEL)
                        onClose(true)
                    } else {
                        setShowError('Something went wrong !')
                        setShowSuccess(null)
                        setIsLoading(false)
                    }
                });
            } else {
                setShowError(response.message)
                setShowSuccess(null)
                setIsLoading(false)
            }
        })
    }
    const options = [
        { title: "Delhi ", value: "Delhi " },
        { title: "Gurgaon", value: "Gurgaon" },
        { title: "Lucknow", value: "Lucknow" },
        { title: "Pune ", value: "Pune " },
        // { title: "Pune (Pimpri)", value: "Pune (Pimpri)" }
    ];

    const reasonOption = [
        { title: "Autism Therapy" },
        { title: "Speech Therapy" },
        { title: "Occupational Therapy" },
        { title: "Special Education" },
        { title: "Psychological Counselling" },
        { title: "ABA/Behavioral Therapy" },
        { title: "Physiotherapy (Upto Age 6)" },
        { title: "Group Therapy" },
        { title: "Parent Counselling" },
    ];

    return (
        <div
            className={` flex items-center justify-center min-w-[290px] max-w-[446px] top-10 left-0 ${display === 'form' ? 'mt-0' : 'mt-0'} `}
        >
            <div
                className={`relative z-50 py-8 px-6  bg-white rounded-[20px] my-auto md:-mt-[75px] w-[415px]  -mt-16  `}
            >

                {
                    display === 'otp' &&
                    <div className="absolute left-4 top-20 overflow-visible">
                        <Image
                            width={20}
                            height={20}
                            className="object-fit  cursor-pointer"
                            onClick={() => {
                                resetOtp({ otp: '' })
                                setUserInfo(null)
                                setShowError(null)
                                setShowSuccess(null)
                                setDisplay('form')
                            }}
                            src="/assets/images/arrow-right.svg" />
                    </div>
                }

                {
                    display === 'form' && (
                        <>
                            <div>
                                <HeadingNew
                                    type="h5"
                                    fontSize="text-xl"
                                    className="text-left"
                                    fontWeight="font-semibold"
                                    fontColor="text-black">
                                    Book your first discounted session.
                                </HeadingNew>

                                <TextNew
                                    type="body"
                                    fontSize="text-base"
                                    className="text-left my-3">
                                    Avail a discount on your first session to try out our services.
                                </TextNew>

                            </div>
                            <div className="mb-3 text-left">
                                <InputGroup
                                    type="text"
                                    label=""
                                    register={register}
                                    errors={errors}
                                    inputClassName="text-sm font-normal border-none   !bg-gray-100  outline-none rounded-xl  "
                                    name="name"
                                    placeholder={"Parent Name"}
                                />

                                <InputGroup
                                    className={"w-full my-3 text-left"}
                                    type="number"
                                    label=""
                                    register={register}
                                    errors={errors}
                                    inputClassName="text-sm font-normal  border-none  !bg-gray-100  outline-none rounded-xl "
                                    name="contact"
                                    placeholder={"Phone Number"}
                                />

                                <Controller
                                    control={control}
                                    name="location"
                                    className="outline-none  "
                                    render={({ field: { onChange, value } }) => (
                                        <div className="text-left text-sm font-normal   ">
                                            <SelectTag
                                                buttonFontSize="text-sm"
                                                options={options}
                                                selectedOption={
                                                    { title: getValues("location") } || { title: "" }
                                                }
                                                placeholder={"Location"}
                                                selectBtnFontSize="text-sm font-normal "
                                                setSelectedOption={(e) => {
                                                    onChange(e.title);
                                                }}
                                            />
                                            {errors.location && (
                                                <div className="text-red-500 text-xs  lowercase first-letter:capitalize">
                                                    {errors.location.message}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <Controller
                                control={control}
                                name="serviceReq"
                                className="outline-none "
                                render={({ field: { onChange, value } }) => (
                                    <SelectTag
                                        selectBtnFontSize="text-sm"
                                        options={reasonOption}
                                        placeholder={"Service Request"}
                                        selectedOption={
                                            { title: getValues("serviceReq") } || { title: "" }
                                        }
                                        setSelectedOption={(e) => {
                                            onChange(e.title);
                                        }}
                                        handleChange={(event) => { }}
                                    />
                                )}
                            />
                            {errors.serviceReq && (
                                <div className="text-red-500 text-xs text-left lowercase first-letter:capitalize mt-1">
                                    {errors.serviceReq.message}
                                </div>
                            )}
                            {
                                showError && <div className="my-2 text-red-600 text-sm font-semibold lowercase first-letter:capitalize mt-1">{showError}</div>
                            }
                            <div className="mt-3 w-full mx-auto     ">
                                <Button
                                    variant="sunshineSecondary"
                                    padding="px-12 md:px-16 "
                                    disabled={isLoading}
                                    type="largeContained"
                                    onClick={handleSubmit(onSubmit)}
                                    width="w-full"
                                >
                                    Submit
                                </Button>
                            </div>
                        </>
                    )
                }
                {
                    display === 'otp' && (
                        < div className="mb-6 relative my-20 w-full bg-white rounded-[20px]">
                            <HeadingNew
                                type="h5"
                                className="text-center"
                                fontWeight="font-semibold"
                                fontColor="text-black"
                            >

                                Let’s verify it’s you!
                            </HeadingNew>

                            <TextNew
                                type="body"
                                className="text-left text-[#232323] mt-6 mb-4">
                                Enter the OTP Received at +91-{userInfo?.contact}
                            </TextNew>

                            <div className="mb-4 ">
                                <div className="text-left">
                                    <InputGroup
                                        type="text"
                                        label=""
                                        register={registerOtp}
                                        errors={errorsOtp}
                                        inputClassName="text-sm font-normal border-none  !bg-gray-100  outline-none rounded-xl  "
                                        name="otp"
                                        placeholder={"Enter Valid OTP"}
                                    />
                                </div>
                                <div className="flex flex-row-reverse mt-2">
                                    <div className=" cursor-pointer font-semibold  text-secondary-700" onClick={() => sendOtp(userInfo, true)}>Resend OTP</div>
                                </div>
                            </div>

                            {
                                showError && <div className="my-2 text-red-600 text-sm  font-semibold">{showError}</div>
                            }
                            {
                                showSuccess && <div className="my-2 text-success-100 text-sm font-semibold">{showSuccess}</div>
                            }
                            <div className="w-full mt-6">
                                <Button
                                    variant="sunshineSecondary"
                                    disabled={isLoading}
                                    type="largeContained"
                                    onClick={handleSubmitOtp(onSubmitOtp)}
                                    className="px-24"
                                    width="w-full"
                                >
                                    {isLoading ? 'Verifing ...' : 'Verify'}
                                </Button>
                            </div>
                        </div>
                    )
                }


            </div>
        </div>
    );
}

export default BookingFormSunshine;
