import React from "react";
import Link from "next/link";
import Container from "@atoms/Container";
import Image from "next/image";
import Text from "@atoms/Text";
import Button from "@atoms/Button";

export default function Footer({ social, containerType = "type10", className, onClickFooter }) {
    return (
        <footer
            className={`bg-secondary-750 text-gray-600 text-base overflow-hidden pt-8 pb-8 lg:pt-12 lg:pb-8 ${className}`}
        >
            <Container type={containerType}>
                <div className=" md:flex items-center justify-between  gap-2 w-full">
                    <div className="md:pb-0 md:px-0 pb-20 md:-mt-20 mt-20">
                        <Link href="#">
                            <Image
                                src={`/assets/images/whiteLogo111.svg`}
                                width={306}
                                height={99}
                                objectPosition="left"
                                objectFit="contain"
                            />
                        </Link>

                        <div className="flex  gap-x-8 pt-6 items-center">
                            {social.map((item) => {
                                return (
                                    <div>
                                        <a
                                            key={item.id}
                                            href={item.url}
                                            target="_blank"
                                        >
                                            <Image
                                                src={`/assets/images/${item.icon}`}
                                                width={32}
                                                height={32}
                                            />
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>



                    <div className="flex md:flex-row flex-col gap-5 lg:gap-[100px] xl:gap-36 justify-around md:pt-28">
                        <div className="">
                            <div className="!font-poppins text-2xl !text-white font-semibold mb-4">
                                Get Started
                            </div>
                            <div className="flex flex-col gap-4">
                                <a
                                    href="https://www.lissun.app/connect-with-the-best-mental-health-counsellor-&-therapist-in-india?from=sunshine"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                                >
                                    Book Initial Consultation
                                </a>
                                <div
                                    className="!font-Nunito !text-white font-bold text-xs lg:text-base cursor-pointer"
                                    onClick={() => onClickFooter("visitCenter")}
                                >
                                    Book a Visit to Centre
                                </div>
                                <div
                                    className="!font-Nunito !text-white font-bold text-xs lg:text-base cursor-pointer"
                                    onClick={() => onClickFooter("exploreSessions")}
                                >
                                    Explore Services
                                </div>
                                <a
                                    href="https://survey.lissun.app/zs/ZZBoZ1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                                >
                                    Free Autism Screening
                                </a>
                                <div
                                    className="!font-Nunito !text-white font-bold text-xs lg:text-base cursor-pointer"
                                    onClick={() => onClickFooter("contactUsSection")}
                                >
                                    Contact Us
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="!font-poppins text-2xl !text-white font-semibold mb-4">
                                Services
                            </div>
                            <div className="flex flex-col gap-4 cursor-pointer">
                                <a
                                    href="https://www.lissun.app/sunshine/parent-child-counselling-for-kids"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="cursor-pointer"
                                >
                                    <Text className="!font-Nunito !text-white !font-bold text-xs lg:text-base">
                                        Parent Child Counselling
                                    </Text>
                                </a>

                                <a
                                    href="https://www.lissun.app/sunshine/special-education-for-kids"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="cursor-pointer"
                                >
                                    <Text className="!font-Nunito !text-white !font-bold text-xs lg:text-base">
                                        Special Education
                                    </Text>
                                </a>

                                <a
                                    href="https://www.lissun.app/sunshine/speech-therapy-for-kids"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="cursor-pointer"
                                >
                                    <Text className="!font-Nunito !text-white !font-bold text-xs lg:text-base">
                                        Speech Therapy
                                    </Text>
                                </a>

                                <a
                                    href="https://www.lissun.app/sunshine/occupational-therapy-for-kids"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="cursor-pointer"
                                >
                                    <Text className="!font-Nunito !text-white !font-bold text-xs lg:text-base">
                                        Occupational Therapy
                                    </Text>
                                </a>

                                <a
                                    href="https://www.lissun.app/sunshine/behavioural-therapy-for-kids"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="cursor-pointer"
                                >
                                    <Text className="!font-Nunito !text-white !font-bold text-xs lg:text-base">
                                        Behavioural Therapy
                                    </Text>
                                </a>
                            </div>
                        </div>

                        <div className="">
                            <div className="!font-poppins text-2xl !text-white font-semibold mb-4">
                                Contact
                            </div>
                            <div className="grid grid-cols-1 gap-3 text-white">
                                <Text className="!text-white text-base !font-normal">
                                    East Patel Nagar, New Delhi:
                                    <span className="block font-bold text-white text-base">
                                        +91 9821688144
                                    </span>
                                </Text>
                                <Text className="!text-white text-base !font-normal">
                                    Sector 51, Gurgaon:
                                    <span className="block font-bold text-white text-base">
                                        +91 9717857568
                                    </span>
                                </Text>
                                <Text className="!text-white text-base !font-normal">
                                    Gomti Nagar, Lucknow:
                                    <span className="block font-bold text-white text-base">
                                        +91 9717857643
                                    </span>
                                </Text>
                                <Text className="!text-white text-base !font-normal">
                                    Magarpatta, Pune:
                                    <span className="block font-bold text-white text-base">
                                        +91 9717858269
                                    </span>
                                </Text>
                            </div>
                        </div>

                        <div className="md:hidden flex flex-col gap-y-3 mt-10 md:mt-0">
                            <a
                                href={`${process.env.NEXT_PUBLIC_MAIN_URL}terms-and-conditions`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                            >
                                Terms & Conditions
                            </a>
                            <a
                                href={`${process.env.NEXT_PUBLIC_MAIN_URL}privacy-policy`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                            >
                                Privacy Policy
                            </a>
                            <a
                                href={`${process.env.NEXT_PUBLIC_MAIN_URL}payment-policy`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                            >
                                Cancellation & Refund policy
                            </a>
                            <a
                                href={`${process.env.NEXT_PUBLIC_MAIN_URL}about-us-best-online-counselling-and-therapy-service-provider-in-india`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                            >
                                About Us
                            </a>
                        </div>
                    </div>



                </div>

                <hr className="w-full md:mt-24 hidden md:block mx-auto"></hr>

                <div className="flex items-center justify-between mt-[72px] md:mt-6 mx-auto md:px-4 cursor-pointer">
                    <div className="!font-Nunito text-base !text-white font-bold uppercase text-center md:text-left">
                        @2024 Mindeye Solutions Private Limited. All Rights Reserved
                    </div>
                    <div className="hidden md:flex items-center md:pb-0 md:px-0 pb-8 gap-6">
                        <a
                            href={`${process.env.NEXT_PUBLIC_MAIN_URL}terms-and-conditions`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                        >
                            Terms and Conditions
                        </a>
                        <a
                            href={`${process.env.NEXT_PUBLIC_MAIN_URL}privacy-policy`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                        >
                            Privacy Policy
                        </a>
                        <a
                            href={`${process.env.NEXT_PUBLIC_MAIN_URL}payment-policy`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                        >
                            Cancellation & Refund policy
                        </a>
                        <a
                            href={`${process.env.NEXT_PUBLIC_MAIN_URL}about-us-best-online-counselling-and-therapy-service-provider-in-india`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="!font-Nunito !text-white font-bold text-xs lg:text-base"
                        >
                            About Us
                        </a>
                    </div>
                </div>

            </Container>
        </footer>
    );
}
