import * as React from "react";
import ProfessionalList from "@molecules/ProfessionalList";
import Container from "@atoms/Container";
import Image from "next/image";
import HeadingNew from "@atoms/HeadingNew";
const TeamOfPractitioners = ({ ProfessionalListData }) => {
    return (
        <section className="relative bg-[left_top_70px] pb-20 md:pb-6  md:bg-center bg-no-repeat bg-cover bg-[#F7E59D] md:bg-practitioners ">
            <div className="absolute md:hidden block -bottom-3  z-50">
                <Image
                    src="/assets/images/sunshine/OurTeamCatPot.svg"
                    width={400}
                    height={60}
                />
            </div>
            <div className="absolute top-4 md:-top-5 left-0 max-w-[90px] md:max-w-max hidden lg:block">
                <Image
                    src="/assets/images/sunshine/banner21.png"
                    width={152}
                    height={306}
                />
            </div>

            <Container type="type11" className="relative">
                <div className="">
                    <HeadingNew
                        type="h1"
                        fontSize="text-xl lg:text-[40px]"
                        fontWeight="font-semibold "
                        className=" leading-normal py-8 lg:pt-[86px] lg:pb-[68px]  text-center md:max-w-max m-auto"
                    >
                        Our team of practitioners at Sunshine
                    </HeadingNew>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-5 gap-2 md:gap-4 -z-10">
                    {ProfessionalListData.map((obj) => {
                        return (
                            <ProfessionalList
                                key={obj.heading}
                                imageSrcSet={obj.img}
                                title={obj.heading}
                                description={obj.description}
                                viewMorelink={obj.viewMorelink}
                            />
                        );
                    })}
                </div>
                
                <div className="hidden md:block -mb-10">
                    <Image
                        src="/assets/images/sunshine/banner23.svg"
                        width={1299}
                        height={144}

                    />
                </div>


            </Container>
        </section>
    );
};

export default TeamOfPractitioners;
