import TextNew from '@atoms/TextNew';
import Image from 'next/image';
import React, { useState, useEffect } from 'react';
import logoUrl from '/public/assets/images/sunshine/whitelogo.svg';

const HeaderDropdownCommon = ({ bgColor, services, locations, onClickFooter }) => {
    const [dropdownOpen, setDropdownOpen] = useState({ services: false, locations: false });
    const [isHoveredServices, setIsHoveredServices] = useState(false);
    const [isHoveredLocations, setIsHoveredLocations] = useState(false);

    const handleMouseEnterServices = () => {
        setIsHoveredServices(true);
    };

    const handleMouseLeaveServices = () => {
        setIsHoveredServices(false);
    };

    const handleMouseEnterLocations = () => {
        setIsHoveredLocations(true);
    };

    const handleMouseLeaveLocations = () => {
        setIsHoveredLocations(false);
    };

    const toggleDropdown = (dropdown) => {
        setDropdownOpen((prev) => ({
            services: dropdown === 'services' ? !prev.services : false,
            locations: dropdown === 'locations' ? !prev.locations : false,
        }));
    };

    const handleClickOutside = (event) => {
        if (!event.target.closest('.dropdown-container')) {
            setDropdownOpen({ services: false, locations: false });
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={`py-2 px-0 ${bgColor}`}>
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex items-center text-white text-2xl font-bold">
                    <a href="https://example.com" target="_blank" rel="noopener noreferrer">
                        <Image
                            width={219}
                            height={76}
                            src={logoUrl}
                            alt="Sunshine logo"
                            className="ml-8 max-w-full aspect-[2.44] max-md:ml-2.5"
                        />
                    </a>
                </div>
                <nav className="space-x-2 lg:space-x-8 flex items-center">
                    <TextNew variant='body' fontSize='text-xs md:text-base' fontWeight='!font-medium' fontFamily='font-Nunito' className='hover-underline'>
                        <a href="https://sunshine.lissun.app/" target="_blank" rel="noopener noreferrer" className="text-white no-underline">Home</a>
                    </TextNew>
                    <div className="relative inline-block text-left dropdown-container">
                        <TextNew variant='body' fontSize='text-xs md:text-base' fontWeight='!font-medium' fontFamily='font-Nunito' className='text-white focus:outline-none hover-underline no-underline'>
                            <div
                                onClick={() => toggleDropdown('services')}
                                className='flex items-center gap-2 cursor-pointer no-underline'
                                onMouseEnter={handleMouseEnterServices}
                                onMouseLeave={handleMouseLeaveServices}
                            >
                                Services
                                <Image
                                    width={18}
                                    height={18}
                                    src={"/assets/images/sunshine/ArrowDown21.svg"}
                                    alt="Arrow down"
                                    className={`transform transition-transform duration-30 ${dropdownOpen.services ? 'rotate-180' : ''}`}
                                />
                            </div>
                            {isHoveredServices && <div className='hover-line'></div>}
                        </TextNew>
                        {dropdownOpen.services && (
                            <div className="dropdown-menu absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    {services.map((service, index) => (
                                        <a key={index} href={service.link} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-[#232323] font-semibold hover:bg-orange-100">
                                            {service.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="relative inline-block text-left dropdown-container">
                        <TextNew variant='body' fontSize='text-xs md:text-base' fontWeight='!font-medium' fontFamily='font-Nunito' className='text-white focus:outline-none hover-underline no-underline'>
                            <div
                                onClick={() => toggleDropdown('locations')}
                                className='flex items-center gap-2 cursor-pointer no-underline'
                                onMouseEnter={handleMouseEnterLocations}
                                onMouseLeave={handleMouseLeaveLocations}
                            >
                                Locations
                                <Image
                                    width={18}
                                    height={18}
                                    src={"/assets/images/sunshine/ArrowDown21.svg"}
                                    alt="Arrow down"
                                    className={`transform transition-transform duration-300 ${dropdownOpen.locations ? 'rotate-180' : ''}`}
                                />
                            </div>
                            {isHoveredLocations && <div className='hover-line'></div>}
                        </TextNew>
                        {dropdownOpen.locations && (
                            <div className="dropdown-menu absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    {locations.map((location, index) => (
                                        <a key={index} href={location.link} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-[#232323] font-semibold hover:bg-orange-100">
                                            {location.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <TextNew variant='body' fontSize='text-xs md:text-base' fontWeight='!font-medium' fontFamily='font-Nunito' className='hover-underline cursor-pointer'>
                        <a className="text-white no-underline whitespace-nowrap" onClick={() => onClickFooter("contactUsSection")}>Contact Us</a>
                    </TextNew>
                </nav>
            </div>
        </div>
    );
};

export default HeaderDropdownCommon;
